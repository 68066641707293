@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "bootstrap/dist/css/bootstrap.min.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif !important;
}
html {
  scroll-behavior: auto !important;
}
button,
Link,
NavLink {
  transition: all 0.3s;
}
.grey-font {
  color: #757883;
}
.orange-font {
  color: #ff5841;
}
.Toastify__close-button:hover{
  box-shadow: none;
}
.duration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  height: auto;
  max-width: 100%;
  padding: 7px;
  border-radius: 25px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

.timeduration-radio-active {
  padding: 6px;
  background-color: #fff;
  color: #ff5551;
  cursor: pointer;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
}

.timeduration-radio {
  padding: 6px;
  cursor: pointer;
  color: #80838d;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-amount input[type="text"] {
  border: 2px solid #d7dcec;
  max-width: 100%;
  padding: 12px;
  border-radius: 25px;
  font-size: 15px;
  color: #80838d;
  padding-right: 40px;
}
.input-amount {
  position: relative;
}
.input-amount .doller-icon {
  position: absolute;
  margin-top: -38px;
  margin-right: 12px;
  right: 0;
  font-size: 25px;
  color: #80838d;
  stroke-width: 1;
}

.donation-form .input-label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 8px;
}

.fund-selection {
  border: 2px solid #d7dcec;
  width: 100%;
  padding: 12px;
  border-radius: 25px;
  font-size: 15px;
  color: #80838d;
}

.card-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  border: 2px solid #d7dcec;
  border-radius: 20px;
}

.card-selection img {
  max-height: 120px;
  max-width: 200px;
}

.arrow-disable {
  padding: 10px;
  color: #b0b7cc;
  background-color: #f6f6f6;
  border-radius: 50%;
}

.arrow-enable {
  padding: 10px;
  color: #ffffff;
  background-color: #ff5551;
  border-radius: 50%;
}

.give-container {
  box-shadow: 10px 10px 10px #d7dcec31;
  border-radius: 30px;
}

.orange-shadow-btn:hover {
  box-shadow: 3px 3px 10px #ff545181;
  transition: all 0.3s;
}

button {
  font-size: 15px;
  font-weight: 700;
}
.social-btn {
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}
.icon {
  border-radius: 50%;
  padding: 7px;
  height: 45px;
  width: 45px;
  color: #ff5551;
  background-color: #fff;
}
.social-btn:hover,
.icon:hover {
  background-color: #ff5551;
  color: rgb(255, 255, 255);
  transition: all 0.3s;
}
.contact-icon {
  font-size: 20px;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.subheader {
  font-size: 12px;
  color: grey;
}
@media only screen and (min-width: 600px) {
  
.Toastify__toast-container--top-center{
  width: 50% !important;
}
}
.list-group-item:hover{
  font-weight: 500;
  background-color: #f6f6f6;
}