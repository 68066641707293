/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.body {
  font-family: "Roboto", sans-serif;
} */

.hero-text {
  font-size: 54px;
}

.hero-section {
  background: linear-gradient(#fff, #ffedeb);
}

.rectangle {
  border-left: 5px solid #ff5551;
  padding-left: 10px;
}

.featurecard {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 1.5px 1.5px 10px #00000015;
  cursor: default;
  width: 100%;
  border: 2px solid transparent;
  transition: all 0.3s;
}

.featurecard:hover {
  border: 2px solid #f7aca1;
  transition: border 0.3s;
  transition-timing-function: ease-in-out;
}

.donation-section-1 {
  background: linear-gradient(90deg, #e0f3ff, #fef2fa);
}

.dot-container {
  position: absolute;
  padding: 35px;
  z-index: -1;
}

.dot-container-2 {
  position: absolute;
  margin-top: 350px;
  right: 0;
  margin-right: 20px;
  z-index: -1;
}

.red-arrows {
  position: absolute;
  margin-top: 350px;
  margin-left: 80px;
}

.blue-arrows {
  position: absolute;
  right: 80px;
  z-index: -1;
}

.pricing-section {
  background-color: #1d1e25;
}

.pricecard {
  height: 820px;
  background-color: #fff;
  border-radius: 25px;
  padding: 25px;
  transition: all 0.3s;
}

.pricecard:hover {
  cursor: pointer;
  box-shadow: 2px 2px 10px #ffffffb2;
  transition: all 0.3s;
}

.pricecard .plan-text {
  font-size: 23px;
  font-weight: 700;
}

.check-icons {
  font-size: 22px;
  color: #03bc82;
}
.plan-check-list {
  margin-block: 20px;
  gap: 10px;
}

.slide {
  margin-inline: 8px;
}

.purchase-btn {
  position: absolute;
  bottom: 20px;
}
.plan-header-card {
  background-color: #f6f6f6;
  border-radius: 15px;
  padding: 20px;
  margin-block: 20px;
}

.plan-desc-header {
  color: #ff5551;
  font-weight: 600;
  font-size: 14px;
}
.plan-header-card span {
  font-size: 14px;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  background: white;
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
  height: 300px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  /* height: 100px; */
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slider .slide {
  height: 200px;
  width: 250px;
}

.slide img {
  max-width: fit-content;
}

.testimonials-section {
  background: linear-gradient(to right, #fef2fa 0%, #e0f3ff);
}

.swiper {
  width: 600px;
  height: 370px;
}

.swiper-slide {
  font-size: 14px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-card {
  border-radius: 35px;
  width: 300px;
  background-color: #fff;
}

.starimg {
  height: 20px;
}

.userimg {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.quoteimg {
  position: absolute;
  margin-left: 200px;
  margin-top: -10px;
  height: 40px;
}

.swiper-pc {
  display: block;
}

.swiper-mobile {
  display: none;
}

.blogcard {
  height: 100%;
  border-radius: 25px;
  box-shadow: 3px 3px 50px #cfdafa3d;
  padding: 15px;
  transition: all 0.3s;
  position: relative;
}

.blogcard:hover {
  box-shadow: 5px 5px 50px #cfdafa8c;
  transition: all 0.3s;
}

.more-hover {
  padding-bottom: 15px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

.blogcard .blog-img {
  width: 100%;
}

.blogcard .blog-img img {
  object-fit: cover;
  height: 220px;
  width: 100%;
  border-radius: 15px;
}

.rightarrow {
  font-size: 16px;
  color: #ff5551;
}

.blogcard:hover .more-hover .rightarrow {
  position: relative;
  animation: mymove 0.5s infinite;
}

@keyframes mymove {
  from {
    left: 0px;
  }

  to {
    left: 5px;
  }
}

.calender-img {
  font-size: 16px;
  margin-right: 10px;
}

/* .blog-img{
    height: 200px;
    width: 290px;
} */

/* .blog-img img{
    height: 200px;
    width: 290px;
} */

.blog-date p {
  font-size: 13px;
}

.blog-date {
  font-weight: 500;
  color: #787c87;
  display: flex;
  align-items: center;
}

.blog-title {
  font-size: 20px;
}

input[type="email"] {
  border: 1px solid #51535c;
  color: white;
}

/* .social-btn{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon{
    color: #ff5551;
    font-size: 25px;
} */

.swiper-pagination-bullet-active {
  background: #000 !important;
}

.path-header {
  background: linear-gradient(#fff, #ffedeb);
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
}

.path-header .path {
  margin-top: 20px;
  height: 25px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.path-header .path img {
  height: 18px;
  margin-inline: 6px;
}

.pathname {
  color: #ff5551;
}

.pathname::first-letter {
  text-transform: capitalize;
}

.react-multi-carousel-list {
  height: 560px;
}

.react-multiple-carousel__arrow:hover {
  box-shadow: none;
}

.info-desc {
  max-width: 600px;
  color: #575758;
}

.features-desc {
  font-weight: 400;
  color: #808080;
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.5rem;
  cursor: default;
}

.features-title {
  font-weight: 700;
  color: #28292c;
  margin-top: 20px;
  font-size: 24px;
  line-height: 1.5rem;
  cursor: default;
}

.btn-close .close-icon {
  color: #b5b5b5;
}

.btn-close:hover {
  box-shadow: none;
}

.signup-input input[type="text"] {
  border: 1px solid #d7dcec;
  max-width: 100%;
  padding: 12px;
  border-radius: 25px;
  font-size: 15px;
  color: #80838d;
  height: 45px;
}
.signup-input {
  position: relative;
  margin-bottom: 10px;
}
.signup-input .input-label {
  font-size: 15px;
  color: #28292c;
  font-weight: 600;
  margin-block: 5px;
}

.signup-input-with-icon input[type="text"] {
  border: 1px solid #d7dcec;
  max-width: 100%;
  padding: 12px;
  border-radius: 25px;
  font-size: 15px;
  color: #80838d;
  padding-right: 40px;
  height: 45px;
}
.signup-input-with-icon input[type="email"] {
  border: 1px solid #d7dcec;
  max-width: 100%;
  padding: 12px;
  border-radius: 25px;
  font-size: 15px;
  color: #80838d;
  padding-right: 40px;
  height: 45px;
}
.signup-input-with-icon {
  position: relative;
  margin-bottom: 10px;
}
.signup-input-with-icon .input-label {
  font-size: 15px;
  color: #28292c;
  font-weight: 600;
  margin-block: 5px;
}
.signup-input-with-icon .input-icon {
  position: absolute;
  margin-top: -32px;
  margin-right: 25px;
  right: 0;
  font-size: 20px;
  color: #80838d;
  stroke-width: 1;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: scale(1);
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .pricecard {
    height: 850px;
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .pricecard {
    height: auto;
    padding-bottom: 80px;
  }
}
@media (max-width: 921px) {
  footer {
    padding-bottom: 30px;
  }

  .react-multiple-carousel__arrow {
    display: none !important;
  }
}

@media (min-width: 921px) {
  .react-multiple-carousel__arrow {
    position: absolute;
    z-index: 1 !important;
    margin: 5% !important;
    background-color: #ff5551 !important;
    height: 10px !important;
    font-size: 14px !important;
  }
}

@media (max-width: 1316px) and (min-width: 1022px) {
  .react-multiple-carousel__arrow {
    margin: 18% !important;
  }
}

.blog-carousel-class {
  position: relative !important;
}

.react-multi-carousel-list {
  position: unset !important;
}

@media (max-width: 921px) {
  .donation-section-1 .grid {
    grid-template-columns: 1fr;
  }

  .donation-section-1 .aspect-photo {
    order: 2;
  }

  .donation-section-1 .space-y-4 {
    order: 1;
  }

  .hero-text {
    /* margin-top: 35px; */
    font-size: 32px;
  }

  .dot-container {
    position: absolute;
    margin-top: -55px;
    right: 0;
  }

  .dot-container-2 {
    display: none;
  }

  .red-arrows {
    display: none;
  }

  .blue-arrows {
    display: none;
  }

  .swiper {
    width: 300px;
    height: 370px;
  }

  .swiper-slide {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .review-card {
    border-radius: 35px;
    width: 300px;
    background-color: #fff;
  }

  .starimg {
    height: 20px;
  }

  .userimg {
    height: 50px;
  }

  .quoteimg {
    position: absolute;
    margin-left: 200px;
    margin-top: -10px;
    height: 40px;
  }

  .swiper-pc {
    display: none;
  }

  .swiper-mobile {
    display: flex;
  }
}
